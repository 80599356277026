window.Stat = {
  track: (params) => {
    fetch("/track", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    });
  },
  trackVisit: (params) => {
    const key = "_x_visit";
    const today = new Date().toISOString().split("T")[0];
    const visit = localStorage.getItem(key);

    if (visit !== today) {
      localStorage.setItem(key, today);
      Stat.track({ metric: "visits", referrer_host: Stat.getReferrerHost(), ...params });
    }
  },
  trackView: (params) => {
    Stat.track({ metric: "views", referrer_host: Stat.getReferrerHost(), ...params });
  },
  getReferrerHost: () => {
    const host = document.referrer && new URL(document.referrer).host;
    return host && host !== window.location.host ? host : null;
  },
};
